import React, { useEffect, useState } from 'react';
import { Spin, Typography, Row, Col, Card, Alert } from 'antd';
import { PricingCard } from './components/pricing_card';
import { PRICING_CARDS_DATA } from './pricing_cards_data';
import { getSubscription, getSubscriptionStatus } from '../../../apis/billing/apis';
import { SubscriptionResponse, SubscriptionStatus } from '../../../apis/billing/interfaces';
import { config } from '../../../config';

export const BillingPage: React.FC = () => {
  const [subscription, setSubscription] = useState<SubscriptionResponse | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const [subData, statusData] = await Promise.all([
          getSubscription(),
          getSubscriptionStatus()
        ]);
        setSubscription(subData);
        setSubscriptionStatus(statusData);
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[80vh]">
        <Spin size="large" />
      </div>
    );
  }

  const isProd = config.nodeEnv === 'production';

  return (
    <div className="p-6 overflow-y-auto max-h-screen">
      <Typography.Title level={2} className="!text-text_selected">
        Subscription & Billing
      </Typography.Title>

      {subscription && (
        <Card className="mb-6 bg-layer2 border-border_unselected">
          <Typography.Title level={4} className="!text-text_selected">
            Current Subscription
          </Typography.Title>
          <Typography.Text className="text-text_selected">
            Plan: {subscription.subscription_level}
          </Typography.Text>
          <br />
          <Typography.Text className="text-text_selected">
            Valid until: {new Date(subscription.end).toLocaleDateString()}
          </Typography.Text>
          <br />
          <Typography.Text className="text-text_selected">
            Requests in Past Month: {subscription.usage?.numOfRequests}
          </Typography.Text>
        </Card>
      )}

      {subscriptionStatus && (
        <Card className="mb-6 bg-layer2 border-border_unselected">
          <Typography.Title level={4} className="!text-text_selected">
            Subscription Status
          </Typography.Title>
          {subscriptionStatus.outOfDate && (
            <Alert
              type="error"
              message="Your subscription has expired"
              className="mb-2"
            />
          )}
          {subscriptionStatus.outOfLimit && (
            <Alert
              type="error"
              message="You have exceeded your token usage limit"
              className="mb-2"
            />
          )}
          {subscriptionStatus.outOfRequestNum && (
            <Alert
              type="error"
              message="You have exceeded your API request limit"
              className="mb-2"
            />
          )}
          {!subscriptionStatus.outOfDate && !subscriptionStatus.outOfLimit && !subscriptionStatus.outOfRequestNum && (
            <Alert
              type="success"
              message="Your subscription is active and in good standing"
              className="mb-2"
            />
          )}
        </Card>
      )}

      <Typography.Title level={4} className="!text-text_selected">
        Available Plans
      </Typography.Title>

      <Row gutter={[16, 16]}>
        {PRICING_CARDS_DATA(isProd).sort((a, b) => {
          // Current plan should be first
          if (subscription?.subscription_level === a.title) return -1;
          if (subscription?.subscription_level === b.title) return 1;
          return 0;
        }).map((card, index) => (
          <Col key={index} xs={24} sm={24} md={24} lg={24}>
            <PricingCard
              title={card.title}
              price={card.price}
              features={card.features}
              paymentLink={card.paymentLink}
              isCurrentPlan={subscription?.subscription_level === card.title}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
