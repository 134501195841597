import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  addDatabaseModalAtom,
  addDatasetModalAtom,
  addDatabaseRedashAtom,
  teamAccessModalAtom,
  mainOptionAtom,
  onbroadingModalAtom,
  historyModalAtom,
  colorthemeAtom,
  featureAtom,
} from '../../recoil/atoms/atoms';
import { useNavigate, useParams } from 'react-router-dom';
import { Features } from './features/features';
import { Datasources } from './datasources/datasources';
import { Conversations } from './conversations/conversations';
import { TeamAccessModal } from './team_access/team_access_modal';
import { BillingPage } from './billing/billing_page';
import { ProfilePage } from './profile/profile_page';
import { AddDatabaseModal } from './datasources/components/add_database_modal';
import { AddDatasetModal } from './datasources/components/add_dataset_modal';
import { Stepper } from './onbroading/stepper';
import { HistoryModal } from './history/history_modal';
import AddDatabaseRedash from './datasources/components/add_database_redash';
import { useEffect } from 'react';
import { FeatureOption, MainOption, Navigation } from './navigation';
import { SUPPORTED_DATABASES } from '../../utils/supported-database';
import { DashboardApp } from './dashboard/DashboardApp';

export const MainPage = () => {
  const navigate = useNavigate();
  const [mainOption, setMainOption] = useRecoilState(mainOptionAtom);
  const { tab, subtab } = useParams();
  const setOption = useSetRecoilState(featureAtom);

  useEffect(() => {
    if (tab !== 'history') {
      if (Object.values(FeatureOption).includes(subtab as FeatureOption)) {
        // this will capture url with feature names like /features/askdata
        setOption(subtab);
      }

      const option = Object.values(MainOption).find((item) => item === tab) as
        | MainOption
        | undefined;
      if (option) {
        setMainOption(
          Object.values(MainOption).find((item) => item === tab) as
            | MainOption
            | undefined
        );
      } else {
        navigate('/features');
      }
    } else {
      setMainOption(MainOption.Conversation);
    }
  });

  const addDatabaseModal = useRecoilValue(addDatabaseModalAtom);
  const addDatabaseRedash = useRecoilValue(addDatabaseRedashAtom);
  const addDatasetModal = useRecoilValue(addDatasetModalAtom);
  const teamAccessModal = useRecoilValue(teamAccessModalAtom);
  const [historyModal] = useRecoilState(historyModalAtom);
  const onbroadingStepperModal = useRecoilValue(onbroadingModalAtom);
  const [colortheme, setColortheme] = useRecoilState(colorthemeAtom);

  const sortedDatabases = [...SUPPORTED_DATABASES].sort((a, b) => {
    if (a.enabled === b.enabled) return 0;
    return a.enabled ? -1 : 1;
  });

  const renderBody = () => {
    switch (mainOption) {
    case MainOption.Features:
      return <Features />;
    case MainOption.Datasource:
      return <Datasources />;
    case MainOption.Conversation || MainOption.History:
      return <Conversations />;
    case MainOption.Billing:
      return <BillingPage />;
    case MainOption.Profile:
      return <ProfilePage />;
    case MainOption.Dashboard:
      return <DashboardApp />;
    }
  };

  useEffect(() => {
    const newTheme = localStorage.getItem('theme') ?? 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    document.body.className = newTheme;
    setColortheme(newTheme);
  }, [colortheme]);

  return (
    <div className="flex w-screen h-screen bg-layer1 text-sm text-text_selected leading-4 text-semibold min-h-fit">
      <Navigation />

      <div className="flex-1 max-w-full overflow-hidden">{renderBody()}</div>

      {addDatabaseRedash && <AddDatabaseRedash data={sortedDatabases} />}
      {addDatabaseModal && <AddDatabaseModal />}
      {addDatasetModal && <AddDatasetModal />}
      {teamAccessModal && <TeamAccessModal />}
      {historyModal && <HistoryModal />}
      {localStorage.getItem('onboardShown') !== 'true' &&
        onbroadingStepperModal && <Stepper />}
    </div>
  );
};
