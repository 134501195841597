import { DialogResponse, SqlResult } from '../../../../../../apis/conversations/interfaces';
import { Ai_Chart, Analytic_Chart } from './ai_chart';
import { Ai_Chart_V1 } from './ai_chart_v1';
import { TableResult } from './table_result';
import { Query } from './query';
import { useCallback, useState } from 'react';
import Avatar from './avatar';
import { Loading } from '../../loading';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

// Add custom markdown styles
const markdownStyles = {
  container: 'markdown-content',
  heading1: 'text-2xl font-bold mb-4 mt-6 pb-1 border-b border-gray-200 dark:border-gray-700',
  heading2: 'text-xl font-bold mb-3 mt-5 pb-1 border-b border-gray-200 dark:border-gray-700',
  heading3: 'text-lg font-bold mb-2 mt-4',
  heading4: 'text-base font-bold mb-2 mt-3',
  paragraph: 'mb-4 leading-relaxed',
  list: 'mb-4 pl-5',
  listItem: 'mb-1',
  table: 'border-collapse border border-gray-300 dark:border-gray-700 mb-4 w-full',
  tableCell: 'border border-gray-300 dark:border-gray-700 p-2',
  tableHeader: 'bg-gray-100 dark:bg-gray-800 font-bold',
  code: 'bg-gray-100 dark:bg-gray-800 p-1 rounded text-sm font-mono',
  codeBlock: 'bg-gray-100 dark:bg-gray-800 p-3 rounded-md my-4 overflow-auto text-sm font-mono',
  blockquote: 'border-l-4 border-gray-300 dark:border-gray-700 pl-4 italic my-4 text-gray-700 dark:text-gray-300',
  hr: 'my-6 border-t border-gray-300 dark:border-gray-700',
  link: 'text-blue-600 dark:text-blue-400 hover:underline',
  image: 'max-w-full h-auto my-4 rounded',
};

enum ResponseTypeEnum {
  text = 'Analyze',
  internal_text = 'Internal Text',
  sql_query = 'Query',
  sql_result = 'Query Result',
  visualization = 'Visualization',
  analytic_charts = 'Data Analytic Charts',
  error_text = 'Error Text'
}

interface ResponseProps {
  responses: DialogResponse[];
  isAsyncDialog?: boolean;
  onGenerate?: (updatedQuery: string) => Promise<void>;
}

export const Response = ({ responses, isAsyncDialog = false, onGenerate }: ResponseProps) => {

  const text = [] as string[];
  const internal_text = [] as string[];
  const sql_query = [] as string[];
  const sql_result = [] as SqlResult[];
  const visualization = [] as string[];
  const analytic_charts = [] as string[];
  const error_texts = [] as string[];

  responses?.map((response: DialogResponse) => {
    if (response?.text) {
      text.push(response.text);
    }
    if (response?.internal_text) {
      internal_text.push(response.internal_text);
    }
    if (response?.sql_query) {
      sql_query.push(response.sql_query);
    }
    if (response?.sql_result) {
      sql_result.push(response.sql_result);
    }
    if (response?.ai_chart) {
      visualization.push(response.ai_chart);
    }
    if (response?.analytic_charts) {
      response.analytic_charts.forEach(chart => {
        analytic_charts.push(chart);
      });
    }
    if (response?.error_text) {
      error_texts.push(response.error_text);
    }
  });

  const [isCollapsed, setIsCollapsed] = useState(true);

  const displayMarkdown = (res: any) => {
    try {
      const content = typeof res === 'object' ? JSON.stringify(res) : res;

      return (
        <div className={markdownStyles.container}>
          <Markdown
            remarkPlugins={[gfm]}
            components={{
              h1: ({children}) => <h1 className={markdownStyles.heading1}>{children}</h1>,
              h2: ({children}) => <h2 className={markdownStyles.heading2}>{children}</h2>,
              h3: ({children}) => <h3 className={markdownStyles.heading3}>{children}</h3>,
              h4: ({children}) => <h4 className={markdownStyles.heading4}>{children}</h4>,
              p: ({children}) => <p className={markdownStyles.paragraph}>{children}</p>,
              ul: ({children}) => <ul className={markdownStyles.list}>{children}</ul>,
              ol: ({children}) => <ol className={markdownStyles.list}>{children}</ol>,
              li: ({children}) => <li className={markdownStyles.listItem}>{children}</li>,
              table: ({children}) => <table className={markdownStyles.table}>{children}</table>,
              th: ({children}) => <th className={`${markdownStyles.tableCell} ${markdownStyles.tableHeader}`}>{children}</th>,
              td: ({children}) => <td className={markdownStyles.tableCell}>{children}</td>,
              code: (props) => {
                const {inline, children, ...rest} = props as any;
                return !inline ? (
                  <code className={markdownStyles.codeBlock} {...rest}>
                    {children}
                  </code>
                ) : (
                  <code className={markdownStyles.code} {...rest}>
                    {children}
                  </code>
                );
              },
              blockquote: ({children}) => <blockquote className={markdownStyles.blockquote}>{children}</blockquote>,
              hr: () => <hr className={markdownStyles.hr} />,
              a: ({href, children}) => <a href={href} className={markdownStyles.link} target="_blank" rel="noopener noreferrer">{children}</a>,
              img: (props) => <img {...props} className={markdownStyles.image} />
            }}
          >
            {content}
          </Markdown>
        </div>
      );
    } catch (err) {
      return <div>{typeof res === 'object' ? JSON.stringify(res) : res}</div>;
    }
  };

  const renderByType = useCallback((responseType: ResponseTypeEnum, input: string[] | SqlResult[]) => {
    try {
      switch (responseType) {
      case ResponseTypeEnum.text:
        return (input.map((res, i) => (<div key={i} style={{ overflowWrap: 'break-word' }}>
          {displayMarkdown(res)}
        </div>)));
      case ResponseTypeEnum.sql_query:
        return input.map((res, i) => (
          <Query
            query={res}
            key={i}
            onGenerate={(updatedQuery) => onGenerate?.(updatedQuery) ?? Promise.resolve()}
          />
        ));
      case ResponseTypeEnum.internal_text:
        return (input.map((res, i) => (<div key={i} style={{ overflowWrap: 'break-word' }}>
          {displayMarkdown(res)}
        </div>)));
      case ResponseTypeEnum.sql_result:
        return (input.map((res, i) => (<TableResult sqlResult={res} key={i} />)));
      case ResponseTypeEnum.visualization:
        return input.map((res, i) => {
          if (typeof res === 'object') {
            // V1
            return <Ai_Chart_V1 ai_chart={JSON.stringify(res)} key={i} />;
          } else {
            // V0
            return <Ai_Chart ai_chart={res.toString()} key={i} />;
          }
        });
      case ResponseTypeEnum.analytic_charts:
        return (input.map((res, i) => (<Analytic_Chart analytic_chart={res} key={i} />)));
      case ResponseTypeEnum.error_text:
        return (input.map((res, i) => (<div key={i} style={{ color: 'red', overflowWrap: 'break-word' }}>{res}</div>)));
      default:
        return null;
      }
    } catch (err) {
      return <div>Malformed Data</div>;
    }
  }, []);

  return (
    <div className='flex flex-col space-y-5 w-full min-h-20 h-auto my-5 text-base'>
      {/* [Text] Result */}
      {text?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Result</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.text, text)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Query */}
      {sql_query?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Query</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.sql_query, sql_query)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Internal Text */}
      {internal_text?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Query Explanation</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            <div className='flex items-center gap-2'>
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className='text-sm hover:bg-gray-100 dark:hover:bg-gray-800 p-1 rounded'
              >
                {isCollapsed ? '▶' : '▲'}
              </button>
              <span>Query Explanation Generated</span>
            </div>
            {!isCollapsed && renderByType(ResponseTypeEnum.internal_text, internal_text)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Query-Result */}
      {sql_result?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Query Result</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.sql_result, sql_result)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Visualization */}
      {visualization?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Visualization</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.visualization, visualization)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* Data Analytic Chart */}
      {analytic_charts?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            <span className='text-xl font-bold font-roboto mt-6'>Visualization</span>
          </div>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.analytic_charts, analytic_charts)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {/* [Text] Error */}
      {error_texts?.length !== 0 &&
        <div className='flex flex-col space-y-5'>
          <div className='ml-6 flex flex-col space-y-4'>
            {renderByType(ResponseTypeEnum.error_text, error_texts)}
            {isAsyncDialog &&
              <div className="h-auto ml-2 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
      {isAsyncDialog && (!responses || responses.length === 0) &&
        <div className='flex flex-col space-y-5'>
          <div className='flex flex-row justify-start w-full'>
            <Avatar isLegion={true} isLoading={isAsyncDialog} />
            {isAsyncDialog &&
              <div className="h-auto ml-2 pt-8 pb-5">
                <Loading />
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
};
