import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  teamAccessModalAtom,
  mainOptionAtom,
  userMetaAtom,
} from '../../../recoil/atoms/atoms';
import { MainOption } from '../navigation';
import { UserType } from '../../../apis/billing/interfaces';
import { SHOW_WIP } from '../../../utils/constants';
import { Link } from 'react-router-dom';

export const Dropdown = ({ isOpen, side }) => {
  const setMainOption = useSetRecoilState(mainOptionAtom);
  const setTeamAccessModal = useSetRecoilState(teamAccessModalAtom);
  const userMeta = useRecoilValue(userMetaAtom);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`text-xs bg-layer2 divide-y divide-active_hover rounded border border-active_hover shadow w-48 ${side ? 'absolute left-16 bottom-10' : 'ml-6'}`}
      onClick={(e) => e.stopPropagation()}
    >
      <ul className="py-2 [&>*:hover]:bg-layer1" aria-labelledby="avatarButton">
        <Link
          to="/profile"
          style={{ textDecoration: 'none' }}
          className="flex gap-3 px-4 py-2 items-center"
          onClick={() => setMainOption(MainOption.Profile)}
        >
          <div className="w-6 h-6 bg-setting dark:bg-lightsetting" />
          Settings
        </Link>
        {SHOW_WIP && (
          <li className="flex gap-3 px-4 py-2 items-center">
            <div className="w-6 h-6 bg-darkequalizer dark:bg-equalizer" />
            Usage
          </li>
        )}

        {userMeta?.userType === UserType.ADMIN && (
          <>
            <li
              className="flex gap-3 px-4 py-2 items-center"
              onClick={() => setTeamAccessModal(true)}
            >
              <div className="w-6 h-6 bg-darkgroups dark:bg-groups"></div>
              Team Access
            </li>

            <li>
              <Link
                to="/billing"
                style={{ textDecoration: 'none' }}
                className="flex gap-3 px-4 py-2 items-center"
                onClick={() => setMainOption(MainOption.Billing)}
              >
                <div className='w-6 h-6 flex items-center justify-center'>
                  <div className="w-4 h-4 bg-billing dark:bg-billing bg-cover" />
                </div>
                Billing
              </Link>
            </li>
          </>
        )}
        <a
          href="https://airtable.com/appuyygjdg38cDXFr/pagIaOAtKsQVxTfdW/form"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          className="flex gap-3 px-4 py-2 items-center"
        >
          <div className="w-6 h-6 flex items-center justify-center">
            <div className="w-4 h-4 bg-feedback dark:bg-darkfeedback bg-cover" />
          </div>
          Feedback
        </a>
        <Link
          to="/sign-out"
          style={{ textDecoration: 'none' }}
          className="flex gap-3 px-4 py-2 items-center"
        >
          <div className="w-6 h-6 dark:bg-darksignout bg-lightsignout" />
          Sign Out
        </Link>
      </ul>
    </div>
  );
};
