import { useEffect, useState, useMemo } from 'react';
import { getAllDatabases as getAllDatabases } from '../../../../apis/datasources/apis';
import { DatabaseDto } from '../../../../apis/datasources/interfaces';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addDatabaseModalAtom,
  curDatabaseAtom,
  databasesAtom,
  addDatabaseRedashAtom,
} from '../../../../recoil/atoms/atoms';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { StatusIndicator } from './status_indicator';

export const Databases = () => {
  const [databases, setDatabases] = useState<DatabaseDto[]>([]);
  const [addDatabaseModal, setAddDatabaseModal] =
    useRecoilState(addDatabaseModalAtom);
  const [addDatabaseRedash, setAddDatabaseRedash] = useRecoilState(addDatabaseRedashAtom);
  const setCurDatabase = useSetRecoilState(curDatabaseAtom);
  const setDatabasesMap = useSetRecoilState(databasesAtom);
  const theme = document.querySelector('html')?.getAttribute('data-theme');

  useEffect(() => {
    getAllDatabases().then((dbList) => {
      setDatabases(dbList);
      const dbMap = new Map();
      dbList.forEach((dbItem) => {
        dbMap.set(dbItem.id, dbItem);
      });
      setDatabasesMap(dbMap);
    });
  }, [addDatabaseModal, addDatabaseRedash]);

  const handleManageDatabase = (database: DatabaseDto) => {
    setCurDatabase(database);
    if (['redash', 'duckdb'].includes(database.connVia)) {
      setAddDatabaseRedash(true);
    } else {
      setAddDatabaseModal(true);
    }
  };

  const columnDefs = useMemo<ColDef<DatabaseDto>[]>(() => [
    { field: 'id' as keyof DatabaseDto, headerName: 'ID', width: 100 },
    { field: 'name' as keyof DatabaseDto, headerName: 'Name', width: 150 },
    { field: 'createdOn' as keyof DatabaseDto, headerName: 'Last Modified', width: 180 },
    {
      field: 'connJson' as keyof DatabaseDto,
      headerName: 'Config',
      width: 250,
      valueFormatter: (params) => JSON.stringify(params.value)
    },
    { field: 'type' as keyof DatabaseDto, headerName: 'Type', width: 120 },
    {
      headerName: 'Index Status',
      width: 120,
      cellRenderer: (params: { data: DatabaseDto }) => (
        <div className="h-full w-full flex items-center justify-center">
          <StatusIndicator databaseId={params.data.id} />
        </div>
      )
    },
    {
      headerName: '',
      width: 120,
      cellRenderer: (params: { data: DatabaseDto }) => (
        <div
          className="h-full w-full flex items-center justify-center cursor-pointer"
          onClick={() => handleManageDatabase(params.data)}
        >
          <div className="flex items-center justify-center px-6 h-8 border rounded border-active_hover bg-layer2 hover:bg-layer3">
            <span>Manage</span>
          </div>
        </div>
      )
    }
  ], []);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    resizable: true,
    suppressMenu: true,
  }), []);

  return (
    <div className="px-6 h-[60vh] w-full">
      <div className="flex items-center gap-3 text-2xl font-bold font-mono">
        <span className="w-6 h-6 bg-database" />
        Databases
      </div>
      <div
        className={`bg-layer2 mt-3 w-full h-full rounded ${databases.length === 0 && 'text-text_unselected'
        }`}
      >
        <div className="h-[calc(100%-60px)] w-full">
          {databases.length ? (
            <div
              className="ag-theme-alpine h-full w-full"
              style={{
                height: '100%',
                width: '100%',
                '--ag-background-color': theme === 'dark' ? '#1e1e1e' : '#ffffff',
                '--ag-header-background-color': theme === 'dark' ? '#2d2d2d' : '#f5f5f5',
                '--ag-odd-row-background-color': theme === 'dark' ? '#1e1e1e' : '#ffffff',
                '--ag-row-hover-color': theme === 'dark' ? '#2d2d2d' : '#f5f5f5',
                '--ag-header-foreground-color': theme === 'dark' ? '#ffffff' : '#000000',
                '--ag-foreground-color': theme === 'dark' ? '#e0e0e0' : '#181818',
                '--ag-border-color': theme === 'dark' ? '#404040' : '#e0e0e0',
                '--ag-row-border-color': theme === 'dark' ? '#404040' : '#e0e0e0',
              } as React.CSSProperties}
            >
              <AgGridReact
                rowData={databases}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                domLayout='normal'
                animateRows={true}
                rowSelection='single'
                suppressColumnVirtualisation={false}
                suppressRowVirtualisation={false}
              />
            </div>
          ) : (
            <div className="h-full flex-1 flex items-center justify-center text-semibold">
              <span>
                There are currently no database connected to your account.
                <br />
                Lets start by importing a new database for enhanced results.
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-row p-3 mt-[-3px] gap-5 rounded-b bg-layer2">
          {/* Hide Native Connection Button */}
          {/* <div
            className="flex gap-1 items-center justify-center h-10 w-44 bg-border_unselected rounded cursor-pointer"
            onClick={() => {
              setCurDatabase(null);
              setAddDatabaseModal(true);
            }}
          >
            <div className="w-6 h-6 bg-blueplus" />
            <span className=" text-text_selected text-sm text-semibold">
              Add New Database
            </span>
          </div> */}

          {/* Hide Redash Button */}
          <div
            className="flex gap-1 items-center justify-center h-10 w-44 bg-border_unselected rounded cursor-pointer"
            onClick={() => {
              setCurDatabase(null);
              setAddDatabaseRedash(true);
            }}
          >
            <div className="w-6 h-6 bg-blueplus" />
            <span className=" text-text_selected text-sm text-semibold">
              Connect to Database
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
