import { instance } from '../base/instance';
import { UserMeta } from '../billing/interfaces';
import { DatabaseDto, DatasetDto, UploadDataset } from './interfaces';

// Database API
export const getAllDatabases = async (): Promise<DatabaseDto[]> => {
  const res = await instance.get('/databases');
  return res.data;
};

export const getUserMeta = async (): Promise<UserMeta> => {
  const res = await instance.get('/usermeta');
  return res.data;
};

export const uploadUserMeta = async (user: UserMeta): Promise<UserMeta> => {
  const res = await instance.post('/usermeta', user);
  return res.data.user;
};

export const upsertDatabase = async (
  database: DatabaseDto
): Promise<DatabaseDto> => {

  if (database.connJson['file']) {
    const formData = new FormData();
    Object.keys(database).forEach((key) => {
      if (key === 'connJson') {
        for (const obj in database.connJson) {
          if (obj === 'file') {
            const metadata = database.connJson['file'][0];
            formData.append('connJson[file][0][uid]', metadata.uid);
            formData.append('connJson[file][0][name]', metadata.name);
            formData.append('connJson[file][0][status]', 'done');
          } else {
            formData.append(`connJson[${obj}]`, database.connJson[obj]);
          }
        }
      } else {
        formData.append(key, database[key]);
      }
    });
    const res = await instance.post('/databases/upsert', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res.data.database;
  } else {
    const res = await instance.post('/databases/upsert', database);
    return res.data.database;
  }
};

export const deleteDatabase = async (id: string): Promise<void> => {
  await instance.delete('/databases/' + id);
};

// Dataset API
export const getAllDatasets = async (): Promise<DatasetDto[]> => {
  const res = await instance.get('/datasets');
  return res.data;
};

// export const getAllSupportedDatasets = async (): Promise<DatasetType[]> => {
//   const res = await instance.get('/usermeta');
//   return res.data.supportedDatasets;
// };

export const uploadDataset = async (
  dataset: UploadDataset
): Promise<DatasetDto[]> => {
  const formData = new FormData();
  dataset.files.forEach((f: File) => formData.append('files', f));
  const metadata = JSON.stringify({ filetype: dataset.type });
  formData.append('metadata', metadata);
  const res = await instance.post('/files/uploads', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

export const upsertDataset = async (
  dataset: DatasetDto
): Promise<DatasetDto> => {
  const res = await instance.post('/datasets/upsert', dataset);
  return res.data.dataset;
};

export const deleteDataset = async (id: string): Promise<void> => {
  await instance.delete('/datasets/' + id);
};

export const reloadDataset = async (id: string): Promise<void> => {
  await instance.post(`/datasets/${id}/reload`, { removeOld: true });
};

export const getDataset = async (id: string): Promise<DatasetDto> => {
  const res = await instance.get('/datasets/' + id);
  return res.data;
};

export const getDatabase = async (id: string): Promise<DatabaseDto> => {
  const res = await instance.get('/databases/' + id);
  return res.data;
};

export const getMetadataStatus = async (databaseId: string): Promise<{ status: string }> => {
  const res = await instance.get(`/metadata/status/${databaseId}`);
  return res.data;
};
