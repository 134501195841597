import { useState } from 'react';
import { getMetadataStatus } from '../../../../apis/datasources/apis';
import { Tooltip } from 'antd';

interface StatusIndicatorProps {
  databaseId: string;
}

export const StatusIndicator = ({ databaseId }: StatusIndicatorProps) => {
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchStatus = async () => {
    if (loading || hasFetched) return;

    setLoading(true);
    try {
      const result = await getMetadataStatus(databaseId);
      setStatus(result.status);
      setHasFetched(true);
    } catch (error) {
      console.error('Error fetching metadata status:', error);
      setStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = () => {
    if (!hasFetched) return 'bg-gray-300';
    if (loading) return 'bg-gray-300';
    switch (status.toLowerCase()) {
    case 'finished':
      return 'bg-green-500';
    case 'processing':
      return 'bg-yellow-500';
    case 'error':
      return 'bg-red-500';
    default:
      return 'bg-gray-500';
    }
  };

  const getStatusText = () => {
    if (!hasFetched) return 'Click or hover to check status';
    if (loading) return 'Loading status...';
    switch (status.toLowerCase()) {
    case 'finished':
      return 'Indexing complete';
    case 'processing':
      return 'Indexing in progress';
    case 'error':
      return 'Indexing failed';
    default:
      return 'Unknown status';
    }
  };

  return (
    <Tooltip title={getStatusText()}>
      <div
        className={`w-3 h-3 rounded-full ${getStatusColor()} cursor-pointer`}
        onClick={fetchStatus}
        onMouseEnter={fetchStatus}
      />
    </Tooltip>
  );
};
